
  import { defineComponent, onMounted, ref } from "vue"
  import { keycloak } from "@/plugins/keycloak";
  import { v4 as uuidv4 } from 'uuid'

  import '@uppy/core/dist/style.css'
  import '@uppy/dashboard/dist/style.css'

  import Uppy from '@uppy/core'
  import Dashboard from '@uppy/dashboard'
  import GoogleDrive from '@uppy/google-drive'
  import Dropbox from '@uppy/dropbox'
  import Url from '@uppy/url'
  import XHRUpload from '@uppy/xhr-upload'

  interface Props {
    provider: string,
    autoProceed: boolean,
    allowMultipleUploads: boolean,
    maxFileSize?: number,
    maxNumberOfFiles?: number,
    minNumberOfFiles?: number,
    allowedFileTypes?: string[]
  }

  export default defineComponent({
    name: 'Uppy',
    props: {
      provider: {
        type: String,
        default: 'local'
      },
      autoProceed: {
        type: Boolean,
        default: false
      },
      allowMultipleUploads: {
        type: Boolean,
        default: false
      },
      maxFileSize: {
        type: Number,
        default: null
      },
      maxNumberOfFiles: {
        type: Number,
        default: 1
      },
      minNumberOfFiles: {
        type: Number,
        default: null
      },
      allowedFileTypes: {
        type: Array,
        default: null
      },
      variant: {
        type: String,
        default: 'outline-primary'
      },
      title: {
        type: String,
        default: 'Hochladen'
      }
    },
    setup(props: Props, { emit }) {
      // console.log('Uppy props: ', props)
      const componentId = uuidv4()
      onMounted(() => {
        const uppy = Uppy({
          id: `uppy-${componentId}`,
          autoProceed: props.autoProceed,
          allowMultipleUploads: props.allowMultipleUploads,
          debug: false,
          restrictions: {
            maxFileSize: props.maxFileSize,
            maxNumberOfFiles: props.maxNumberOfFiles,
            minNumberOfFiles: props.minNumberOfFiles,
            allowedFileTypes: props.allowedFileTypes
          }
        })

        let trigger = `#uppy-select-files-${componentId}`
        uppy
          .use(Dashboard, {
            id: `Dashboard-${componentId}`,
            trigger: trigger,
            closeAfterFinish: true,
          })
          // .use(GoogleDrive, { target: Dashboard, companionUrl: 'http://localhost:3020/' })
          // .use(Dropbox, { target: Dashboard, companionUrl: 'http://localhost:3020' })
          // .use(Url, { target: Dashboard, companionUrl: 'http://localhost:3020/' })
          .use(XHRUpload, {
            endpoint: `${process.env.VUE_APP_API}/file_meta_datas`,
            formData: true,
            fieldName: 'files[]',
            //headers: { 'Authorization': 'Boubou' }
            bundle: false,
            headers: () => ({ 'Authorization': `Bearer ${keycloak.token}` })
          })
          .on('complete', (result) => {
            uppy.reset()
            emit('complete', result)
          })
      })

      return {
        componentId
      }
    }
  })
